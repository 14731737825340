<template>
  <div class="service">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-input v-model="search" placeholder="请输入合同模板名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-button type="primary" @click="handleAdd">新增</el-button>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column prop="templateName" label="合同模板名称"></el-table-column>
          <el-table-column prop="phone" label="合同类型">
            <template slot-scope="scope">
              <span v-if="scope.row.contractType == 1">商超招标合同模板</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="200">
            <template slot-scope="scope">
              <el-button class="btn" type="" size="" plain @click="handleView(scope.$index, scope.row)">查看</el-button>
              <el-button class="btn" type="danger" size="" plain @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>

    <el-dialog title="新增" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="合同类型" prop="contractType">
              <el-select v-model="ruleForm.contractType" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同模板名称" prop="templateName">
              <el-input v-model="ruleForm.templateName" placeholder="默认根据文件名识别，可修改"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="模板上传" prop="upload">
              <el-upload :action="apiBaseUrl" name="multipartFile" :headers="apiHeaders" :limit="1" :file-list="importFiles" accept=".pdf" :on-success="handleSuccess" :before-upload="beforeUpload">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip" style="color: red">提示：只能上传 PDF 文件，且大小不超过500MB</div>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12"></el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      loading: false,
      submitLoding: false,
      isAdd: true,
      search: "",
      tableData: [],
      fileUrl: "",
      fileId: "",

      options: [
        {
          value: "1",
          label: "商超招标合同模板",
        },
      ],
      ruleForm: {
        contractType: "",
        templateName: "",
      },
      rules: {
        contractType: [{ required: true, message: "请输入合同类型", trigger: "submit" }],
        templateName: [{ required: true, message: "请输入模板名称", trigger: "submit" }],
        // upload: [{ required: true, message: '请选择文件', trigger: 'submit' }],
      },
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      apiBaseUrl: this.$axios.defaults.baseURL + this.$api.uploadFile,
      apiHeaders: {
        token: this.$store.state.token,
      },
      importFiles: [],
    }
  },
  created() {
    this.templateList()
  },
  methods: {
    // 列表
    templateList() {
      this.loading = true
      this.$axios
        .get(this.$api.templateList, {
          params: {
            templateName: this.search,
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.tableData = res.data.result
            this.totalItemsCount = res.data.result.totalCount
            this.loading = false
          } else {
            this.loading = false
          }
        })
    },
    // 搜索
    onSearch() {
      this.currentPage = 1
      this.templateList()
    },
    // 新增（对话框）
    handleAdd() {
      this.isAdd = true
      this.dialogVisible = true
    },
    // 查看
    handleView(index, row) {
      localStorage.setItem("fileUrl", row.fileUrl)
      window.open(row.fileUrl)
      // this.$router.push({ path: '/contract/detail/preview' })
    },
    // 删除
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该内容, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios.post(this.$api.delTemplate + row.id).then(res => {
            if (res.data.code == 100) {
              this.$message.success(res.data.desc)
            }
            this.templateList()
          })
        })
        .catch(() => {})
    },
    // 提交表单
    submitForm(formName) {
      let param = {
        contractType: this.ruleForm.contractType,
        templateName: this.ruleForm.templateName,
        fileId: this.fileId,
        fileUrl: this.fileUrl,
      }
      this.submitLoding = true
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios.post(this.$api.uploadTemplate, param).then(res => {
            if (res.data.code == 100) {
              this.$message.success("添加成功")
            }
            this.templateList()
            this.submitLoding = false
            this.dialogVisible = false
            this.resetForm()
          })
        } else {
          this.submitLoding = false
          this.$message.error("提交失败")
          return false
        }
      })
    },
    // 关闭对话框
    onCancel() {
      this.resetForm()
      this.dialogVisible = false
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.templateList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.templateList()
    },
    // 重置表单
    resetForm() {
      this.ruleForm = {
        contractType: this.ruleForm.contractType,
        fileUrl: this.ruleForm.fileUrl,
      }
      this.importFiles = []
      this.$refs.ruleForm.resetFields()
    },

    beforeUpload(file) {
      // console.log(file);
      // const suffixArr = ["doc", "docx", "dot", "dotx", "docm", "dotm"];
      const suffixArr = ["pdf"]
      const maxSize = 1024 * 1024 * 500
      if (!suffixArr.includes(file.name.split(".").pop())) {
        // this.$message.error(`只能上传"doc""docx""dot""dotx""docm""dotm"文件`);
        this.$message.error(`只能上传 PDF 文件`)
        return false
      }
      if (file.size > maxSize) {
        this.$message.error("附件不能超过500M")
        return false
      }
    },
    extractBeforeDot(str) {
      const dotIndex = str.lastIndexOf(".")
      return dotIndex !== -1 ? str.substring(0, dotIndex) : str
    },
    handleSuccess(res) {
      if (res.code == 100) {
        this.$message.success(res.desc)
        this.fileId = res.result.fileId
        this.fileUrl = res.result.fileUrl
        this.ruleForm.templateName = this.extractBeforeDot(res.result.fileName)
      } else {
        this.$message.error(res.desc)
        this.importFiles = []
      }
    },
  },
}
</script>

<style scoped lang="scss">
.service {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;

      ::v-deep .el-input__inner {
        border-radius: 10px;
      }
    }

    .el-button {
      margin-left: 20px;
    }
  }
}
</style>
